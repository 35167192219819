// list endpoints
export const list_employees = { path: "getAllEmployees", method: "get" };
export const list_station = { path: "getAllStations", method: "get" };
export const list_work_order = { path: "getAllWorkOrders", method: "get" };
export const list_inspection = { path: "getAllInspections", method: "get" };

// edit and create endpoints
export const create_work_order = { path: "createWorkOrder", method: "post" };
export const modify_work_order = "";
export const update_status_work_order = {
  path: "approveWorkOrder",
  method: "post",
};
export const reject_work_order = "";
export const approve_inspection = {
  path: "approveInspection",
  method: "post",
};
export const assign_engineer = {
  path: "assignEngineerToWorkOrder",
  method: "post",
};
export const edit_inspection = {
  path: "editInspection",
  method: "post",
};

// view individual endpoints
export const view_employee = { path: "getEmployeeById", method: "post" };
export const view_station = { path: "getStationById", method: "post" };
export const view_work_order = { path: "getWorkOrderById", method: "post" };
export const view_inspection = { path: "getInspectionById", method: "post" };

export const add_file = {
  path: "addFile",
  method: "post",
};
export const all_expired = {
  path: "getAllFilesExpired",
  method: "post",
};
export const all_near_expiry = {
  path: "getAllFilesNearExpiry",
  method: "post",
};
export const all_files = {
  path: "getAllF",
  method: "post",
};
export const near_and_expired = {
  path: "getAllFilesNearAndExpired",
  method: "post",
};
export const near_and_expired_by_id = {
  path: "getAllFilesNearAndExpiredById",
  method: "post",
};
export const del_file = {
  path: "delF",
  method: "post",
};

export const logout = { path: "logout", method: "get" };
