import axios from "axios";
import { makeUseAxios } from "axios-hooks";

const local = "http://localhost:3001";
const rpi = "http://192.168.1.3:3001";
const remote_local = "http://192.168.1.25:3001";
const remote = "https://api.tankoncloud.com";
const dev = "https://connect.aura360.app";

export const remote_files = "http://157.245.94.5:3020";

export const current_host = remote;

const config = {
  withCredentials: true,
  headers: {},
};

const IS_PROD = process.env.IS_PROD;

if (!IS_PROD) {
  config.headers["Dev-che"] = true;
}

export const baseRoutes = makeUseAxios({
  axios: axios.create({
    baseURL: current_host + "/api/",
    ...config,
  }),
});
export const MEAuthRoutes = makeUseAxios({
  axios: axios.create({
    baseURL: current_host + "/api/auth/me/",
    ...config,
  }),
});
export const managerRoutes = makeUseAxios({
  axios: axios.create({
    baseURL: current_host + "/api/m/",
    ...config,
  }),
});

export const employeeRoutes = makeUseAxios({
  axios: axios.create({
    baseURL: current_host + "/api/e/",
    ...config,
  }),
});
export const userRoutes = makeUseAxios({
  axios: axios.create({
    baseURL: current_host + "/api/u/",
    ...config,
  }),
});
